/*
 * MIXINS
 */

// transition
@mixin transition($el, $time, $easing, $delay) {
	-webkit-transition: $el $time $easing $delay;
	-moz-transition: $el $time $easing $delay;
	-ms-transition: $el $time $easing $delay;
	-o-transition: $el $time $easing $delay;
	transition: $el $time $easing $delay;
}

// transform
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	-o-transform: $transforms;
	transform: $transforms;

	/*-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;*/

	will-change: transform;
}

// transform origin
@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-o-transform-origin: $origin;
	transform-origin: $origin;
}

// rotate
@mixin rotate ($deg) {
	@include transform(rotate3d(1,1,1,#{$deg}deg));
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
} 

// keyframe
@mixin keyframe($animation_name) {
	@-webkit-keyframes #{$animation_name} {
		@content;
	}

	@-moz-keyframes #{$animation_name} {
		@content;
	}

	@-ms-keyframes #{$animation_name} {
		@content;
	}

	@-o-keyframes #{$animation_name} {
		@content;
	}

	@keyframes #{$animation_name} {
		@content;
	}
}

// animation
@mixin animation($animation, $duration, $ease, $delay, $mode) {
	-webkit-animation-name: #{$animation};
	-webkit-animation-duration: $duration;
	-webkit-animation-timing-function: $ease;
	-webkit-animation-delay: $delay;
	@if $mode == forwards {
		-webkit-animation-fill-mode: $mode;
	} @else {
		-webkit-animation-iteration-count: $mode;
	}

	-moz-animation-name: #{$animation};
	-moz-animation-duration: $duration;
	-moz-animation-timing-function: $ease;
	-moz-animation-delay: $delay;
	@if $mode == forwards {
		-moz-animation-fill-mode: $mode;
	} @else {
		-moz-animation-iteration-count: $mode;
	}

	-ms-animation-name: #{$animation};
	-ms-animation-duration: $duration;
	-ms-animation-timing-function: $ease;
	-ms-animation-delay: $delay;
	@if $mode == forwards {
		-ms-animation-fill-mode: $mode;
	} @else {
		-ms-animation-iteration-count: $mode;
	}

	-o-animation-delay: $delay;
	-o-animation-duration: $duration;
	-o-animation-name: #{$animation};
	@if $mode == forwards {
		-o-animation-fill-mode: $mode;
	} @else {
		-o-animation-iteration-count: $mode;
	}

	animation-name: #{$animation};
	animation-duration: $duration;
	animation-timing-function: $ease;
	animation-delay: $delay;
	@if $mode == forwards {
		animation-fill-mode: $mode;
	} @else {
		animation-iteration-count: $mode;
	}
}

// border-radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
	border-radius: $radius;
}

// breakpoint
@mixin breakpoint($size) {
    @media screen and (max-width: $size) { @content; }
}

// make sprites
@mixin make-sprite($posX, $posY, $width, $height) {
	background-position: #{$posX}px #{$posY}px;
	width: #{$width}px;
	height: #{$height}px;
}