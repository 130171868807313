@charset "utf-8";
/* CSS Document */

/*
 * FONTS IMPORT
 */

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,900');


/*
 * FONTS
 */

// custom fonts
$rubik: 'Rubik', sans-serif;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-black: 900;

// sizes
$base-font-size: 16px;

$font-9: 0.563em;
$font-10: 0.625em;
$font-11: 0.688em;
$font-12: 0.750em;
$font-14: 0.875em;
$font-15: 0.938em;
$font-18: 1.125em;
$font-19: 1.188em;
$font-20: 1.250em;
$font-22: 1.375em;
$font-24: 1.500em;
$font-25: 1.563em;
$font-26: 1.625em;
$font-28: 1.750em;
$font-30: 1.875em;
$font-34: 2.125em;
$font-36: 2.250em;
$font-38: 2.375em;
$font-40: 2.500em;
$font-45: 2.813em;
$font-46: 2.875em;
$font-48: 3.000em;
$font-50: 3.125em;

// colors
$text-color: #313131;
$black: #000;
$white: #fff;
$grey: #c4c4c4;


/*
 * BREAKPOINT VAR
 */

$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

/*
 * MIXINS IMPORT
 */

@import "_mixins";


/*
 * UTEIS IMPORT
 */

@import "_uteis";


/*
 * DEFAULT TEMPLATE CSS
 */

body {
	background-color: $white;
	font-family: $rubik;
	font-size: $base-font-size;
	font-weight: $fw-light;
	color: $text-color;
	/*-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-smoothing: antialiased;
	font-smoothing: antialiased;
	overflow-x: hidden;*/
}

/*a {
	color: $text-color;
	text-decoration: none !important;
	@include transition(all, 0.2s, ease-in, 0s);

	&:hover, &:active { color: $black !important; text-decoration: none !important; }
}*/

a.link-grey {
	color: $grey;

	&:hover { color: $text-color; }
}

b { font-weight: 500; }

div#wrapper {
    width: 100%;
    height: auto;
}

// forms

.form-group {
	margin-bottom: 25px;

	label {
	    font-size: $font-14;
	    font-weight: $fw-medium;
	}
}

.input-group-addon {
    @include border-radius(0);
}

.form-control {
    background-color: $white;
    max-width: 100%;
    height: 38px;
    padding: 7px 12px;
    color: #565656;
    border: 1px solid #e4e7ea;
    border-radius: 0;
    transition: all 300ms linear 0s;

    &:focus {
	    border-color: #313131;
	    box-shadow: none;
	}
}

textarea.form-control {
    height: auto;
}

.article-title--wrapper {
	padding: 0 110px;

	.article-title {
		min-height: 48px;
		margin: 45px 0 0;
		padding: 0;
		font-size: 42px;
		font-weight: 500;
		line-height: 48px;
		border: none;
		box-shadow: none !important;
		overflow-wrap: break-word;
	    overflow: hidden;
	}
}

.has-error {
	border-color: #a94442 !important;
}

.cover-container {
	background-color: #f3f6f8;
    width: calc(100% + 50px);
    height: 280px;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #e1e9ee;
    transition: height 334ms ease 0s;

    &.cover-image__hasimg {
    	background-color: transparent;
		border-bottom: medium none;
		height: 600px;
		max-height: calc(100vh - 92px - 13rem);

		&:hover {
			.cover-image__toolbar {
				opacity: 1;
			}
		}
    }

    &.cover-image--fixed {
		height: 400px;
		max-height: none;
	}

    .cover-image__drop {
    	border: 2px solid transparent;
	    box-sizing: content-box;
	    height: 100%;
	    margin: 0;
	    position: relative;

	    &.active {
	    	border-color: #008cc9;
	    	border-style: dashed;
	    }

	    label {
			background-image: url('../img/icon-cover.png');
			background-position: center;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			font-size: 21px;
			font-weight: 400;
			line-height: 28px;
			color: rgba(0, 0, 0, 0.85);
			opacity: 0.5;
			text-indent: -9999px;
			transition-delay: 0s;
			transition-duration: 334ms;
			transition-property: opacity;
			transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
			cursor: pointer;

			&:hover { opacity: 1; }
	    }

	    input[type=file] {
			bottom: 100%;
			position: fixed;
			right: 100%;
	    }
    }
}

.cover-image__crop {
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	transition: width 334ms ease 0s;
	width: 100%;
}

.cover-image__crop--fixed {
	width: 744px;
}

.cover-image__image {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	transition-duration: 334ms;
	transition-property: filter, transform, background-position, -webkit-filter, -webkit-transform;
	transition-timing-function: ease;
	width: 100%;
}

.img-blur { filter: blur(10px); }

.cover-image__toolbar {
	// background: rgba(0, 0, 0, 0) linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1)) repeat scroll 0 0;
	// bottom: 2px;
	bottom: 30px;
	font-size: 0;
	height: 40px;
	opacity: 0;
	// padding: 44px 0 16px;
	position: absolute;
	text-align: center;
	transition: opacity 334ms ease 0s;
	width: 100%;
	z-index: 999;

	ul {
		// background-color: #2f323e;
		background-color: rgba(47,50,62,.75);
		width: auto;
		// height: 40px;
		padding: 8px 5px;
		margin: 0 auto;
		@include border-radius(40px);
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.15);
		display: inline-block;

		li {
			margin: 0 4px;
			display: inline-block;

			// &:last-child { margin-right: 0; }

			button {
				background-color: $white;
				width: 36px;
				height: 36px;
				padding: 0 6px;
				transform-origin: 50% 100% 0;
				transition: all 0.3s ease 0s;
				will-change: transform;
				border: none;
				@include border-radius(50%);
				cursor: pointer;
				overflow: hidden;
				display: block;

				&:hover, &.active {
					background-color: #008cc9;
					color: $white;
				}

				&:hover {
					transform: scale(1.2);
					transition: all 0.2s ease-in 0s;
				}

				svg {
					margin-top: 6px;
				}
			}
		}
	}
}

.btn {
	font-size: $font-14;
    @include border-radius(3px);

    i { margin-right: 5px; }
}

.btn-large {
	font-size: $font-18;
	padding: 10px 16px;
}

.btn-default {
    background: #e4e7ea none repeat scroll 0 0;
    border: 1px solid #e4e7ea;

    &:hover, &:focus {
    	background: #e4e7ea none repeat scroll 0 0;
	    border: 1px solid #e4e7ea;
	    opacity: 0.8;
    }
}

.btn-add {
    background: #53e69d none repeat scroll 0 0;
    border: 1px solid #53e69d;
    color: $white;

    &:hover, &:focus {
		background: #53e69d none repeat scroll 0 0;
		color: $white;
		border: 1px solid #53e69d;
		opacity: 0.8;
    }
}

.btn-fixo-add {
    background-color: #337ab7;
    width: 56px;
    height: 56px;
    position: fixed;
    // bottom: 88px;
    bottom: 24px;
    right: 24px;
    font-size: $font-14;
    color: $white;
    text-align: center;
    white-space: nowrap;
    border: medium none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.25) inset, 0 -1px 0 rgba(0, 0, 0, 0.15) inset;   
    transition: all .2s ease-in-out;
    z-index: 999;

    &:before {
    	content: "";
		position: absolute;
		top: -20px;
		right: -20px;
		height: 96px;
		width: 96px;
		z-index: 10;
		border: 5px solid #337ab7;
		border-radius: 70px;
		-moz-animation: heartbit 1.4s ease-out;
		-moz-animation-iteration-count: infinite;
		-o-animation: heartbit 1.4s ease-out;
		-o-animation-iteration-count: infinite;
		-webkit-animation: heartbit 1.4s ease-out;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;

		@include keyframe(heartbit) {
			0% {
				-webkit-transform: scale(0);
				-moz-transform: scale(0);
				-o-transform: scale(0);
				transform: scale(0);
				opacity: 0.0;
			}
			25% {
				-webkit-transform: scale(0.1);
				-moz-transform: scale(0.1);
				-o-transform: scale(0.1);
				transform: scale(0.1);
				opacity: 0.1;
			}
			50% {
				-webkit-transform: scale(0.5);
				-moz-transform: scale(0.5);
				-o-transform: scale(0.5);
				transform: scale(0.5);
				opacity: 0.3;
			}
			75% {
				-webkit-transform: scale(0.8);
				-moz-transform: scale(0.8);
				-o-transform: scale(0.8);
				transform: scale(0.8);
				opacity: 0.5;
			}
			100% {
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-o-transform: scale(1);
				transform: scale(1);
				opacity: 0.0;
			}
		}
    }

    &:hover {
    	// background-color: #2cabe3;
    	background-color: #286090;
    	font-size: $font-24;

    	&:before {
			-webkit-animation-play-state: paused;
			-moz-animation-play-state: paused;
			-o-animation-play-state: paused;
			animation-play-state: paused;
    	}
    }

    span {
		font-weight: 700;
		line-height: 56px;
    }
}

.btn-login {
    background: #2cabe3 none repeat scroll 0 0;
    border: 1px solid #2cabe3;
    color: $white;

    &:hover, &:focus {
		background: #2cabe3 none repeat scroll 0 0;
		color: $white;
		border: 1px solid #2cabe3;
		opacity: 0.8;
    }
}

.btn-fullwidth { width: 100%; }

// listagem

.table {
	th {
		cursor: pointer;
	}
}

/*
 * NAV TOP
 */

nav.navbar-static-top {
    position: fixed;
    z-index: 1010;
}

nav.navbar-default {
    width: 100%;
    top: 0;
    border: 0 none;
}

div.navbar-header {
    background-color: #0e0f11;
    width: 100%;
    height: 60px;
    border: 0 none;
}

/* logo part */

div.top-left-part {
    width: 240px;
    height: 60px;
    float: left;
    border-right: 1px solid rgba(0, 0, 0, 0.08);

    .logo {
		width: 100%;
		height: 60px;
		padding: 0 10px;
		line-height: 60px;
	}
}

/* fim logo part */

/* links top */

.navbar-top-links {
    margin-right: 0;
    height: 60px;

    li {
	    float: left;
	    height: 60px;

	    &.open{

	    	a {
				&:hover, &:focus{
					background-color: #333;
				}
	    	}

	    }

		& > a {
			color: #fff;
			line-height: 60px;
			min-height: 60px;
			padding: 0 14px;

			&:hover, &:focus{
				background-color: #333;
			}

			.notify {
				position: relative;
				margin-top: -30px;

				.heartbit {
					position: absolute;
					top: -20px;
					right: -16px;
					height: 25px;
					width: 25px;
					z-index: 10;
					border: 5px solid #ff7676;
					border-radius: 70px;
					-moz-animation: heartbit 1s ease-out;
					-moz-animation-iteration-count: infinite;
					-o-animation: heartbit 1s ease-out;
					-o-animation-iteration-count: infinite;
					-webkit-animation: heartbit 1s ease-out;
					-webkit-animation-iteration-count: infinite;
					animation-iteration-count: infinite;

					@include keyframe(heartbit) {
						0% {
							-webkit-transform: scale(0);
							-moz-transform: scale(0);
							-o-transform: scale(0);
							transform: scale(0);
							opacity: 0.0;
						}
						25% {
							-webkit-transform: scale(0.1);
							-moz-transform: scale(0.1);
							-o-transform: scale(0.1);
							transform: scale(0.1);
							opacity: 0.1;
						}
						50% {
							-webkit-transform: scale(0.5);
							-moz-transform: scale(0.5);
							-o-transform: scale(0.5);
							transform: scale(0.5);
							opacity: 0.3;
						}
						75% {
							-webkit-transform: scale(0.8);
							-moz-transform: scale(0.8);
							-o-transform: scale(0.8);
							transform: scale(0.8);
							opacity: 0.5;
						}
						100% {
							-webkit-transform: scale(1);
							-moz-transform: scale(1);
							-o-transform: scale(1);
							transform: scale(1);
							opacity: 0.0;
						}
					}
				}

				.point {
					width: 6px;
					height: 6px;
					border-radius: 30px;
					background-color: #ff7676;
					position: absolute;
					right: -6px;
					top: -10px;
				}
			}
		}
	}

	.dropdown-menu {
		li {
			display: block;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

// dropdown
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0 8px;
	margin: 0;
	font-size: $font-14;
	text-align: left;
	list-style: none;
	background-color: #333;
	background-clip: padding-box;
	border: 1px solid rgba(120, 130, 140, 0.13);
	@include border-radius(0);
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);

	li {
		height: auto;
		float: none;
		display: block;

		&:last-child { margin-right: 0; }

		a {
			min-height: auto;
			display: block;
			padding: 9px 20px;
			clear: both;
			font-weight: $fw-regular;
			line-height: 1.42857143;
			color: #fff;
			white-space: nowrap;

			&:hover, &:focus {color: #fafafa;}

			div { white-space: normal; }
		}
	}
}

.dropdown-user {
	right: 0;
	left: auto;
	width: 280px;
}

.open > .dropdown-menu {
	display: block;
}

.caret {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px dashed;
    display: inline-block;
    height: 0;
    margin-left: 4px;
    vertical-align: middle;
    width: 0;
}

.app-search {
    margin: 0;
    position: relative;

    .form-control {
		background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
		border: medium none;
		border-radius: 40px;
		box-shadow: none;
		color: #4c5667;
		display: inline-block;
		font-size: 13px;
		font-weight: 500;
		height: 30px;
		line-height: 30px;
		margin-top: 15px;
		padding-left: 20px;
		padding-right: 40px;
		transition: all 0.5s ease-out 0s;
		width: 180px;
		border: 1px solid rgba(120, 130, 140, 0.13);

	    &:focus {
	    	@extend .form-control;
	    	width: 300px;
	    }
	}

	a {
		color: #4c5667;
		position: absolute;
		right: 10px;
		top: 20px;

		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
}

.profile-pic {
    font-size: 14px;

    // img { margin-right: 10px; }
    div.user-img {
    	background-position: 50% 25%;
    	background-size: cover;
    	background-repeat: no-repeat;
    	width: 36px;
    	height: 36px;
    	margin-right: 10px;
    	@include border-radius(50%);
    	display: inline-block;
    	vertical-align: middle;
    }
}

/*
 * SIDEBAR
 */

.sidebar {
    background: #2f323e none repeat scroll 0 0;
    width: 240px;
    height: 100%;
    padding-top: 0;
    position: fixed;
    transition: all 0.05s ease-in 0s;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    z-index: 1001;

    [class^="ti-"], [class*=" ti-"] {
	    display: inline-block;
	    /*font-size: 18px;*/
	    font-style: normal;
	    font-weight: 500;
	    margin-right: 7px;
	    margin-top: -4px;
	    text-align: center !important;
	    vertical-align: middle;
	    width: 20px;
	}

	&.closed { left: -240px; }
}

#side-menu {
    overflow: hidden;

    li {
    	position: relative;
    	cursor: pointer;

    	a {
			width: 240px;
			padding: 15px 35px 15px 20px;
			font-size: 14px;
			color: #97999f;
			border-left: 0 solid #2f323e;
			display: block;

			&:hover, &:focus { background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0; }

			&.active {
				background: #2cabe3 none repeat scroll 0 0;
				color: #ffffff;
				font-weight: 500;
			}
    	}

		.label {
			border-radius: 60px;
			font-size: $font-10;
			height: 20px;
			// margin-top: 4px;
			min-width: 30px;
			padding: 6px 8px;

			&.label-inverse { background-color: #4c5667; }
		}

    	.nav-second-level {
    		transition: all .25s ease;

    		& > li > a {
				padding: 14px 10px 14px 40px;

				&:hover {
					background: transparent;
					color: $white;
				}
			}
		}

		.arrow {
			position: absolute;
			right: 0;
			top: 25px;
			font-size: $font-10;
		}
    }
}

.slimScrollDiv { padding-bottom: 145px; }


/*
 * PAGE CONTENT
 */

#page-wrapper {
    background: #edf1f5 none repeat scroll 0 0;
    min-height: calc(100vh - 60px);
    // padding: 0 0 60px;
    padding: 0 0 15px;
    margin: 60px 0 0 240px;
    position: relative;

    &.submenu-closed { margin-left: 0; }
}

.bg-title {
    background: #f1f2f7 none repeat scroll 0 0;
    padding: 15px 10px 9px;
    margin-bottom: 25px; 
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    overflow: hidden;

    h4 {
	    margin-top: 6px;
	    font-size: 14px;
	    font-weight: 500;
	    line-height: 22px;
	    text-transform: uppercase;
	}

	.breadcrumb {
	    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	    padding: 0;
	    margin-bottom: 0;
	    margin-top: 8px;
	    float: right;
	    font-size: 14px;

	    li {
		    display: inline-block;

		    &.active { color: #2cabe3; }

		    a {
		    	color: rgba(0, 0, 0, 0.5);

		    	&:hover { color: #000; }
		    }
		}
	}
}

.content-box {
    background-color: #fff;
    // margin-bottom: 30px;
    margin-bottom: 15px;
    padding: 25px;

    .box-title {
	    margin: 0;
	    font-size: 16px;
	    font-weight: 500;
	    line-height: 30px;
	    text-transform: uppercase;
	}

	.box-desc {
	    margin-bottom: 30px;
	    font-size: 13px;
	    color: #8d9ea7;
	}
}


/*
 * LOGIN
 */

.bg-grey {
	background-color: #edf1f5;
	width: 100%;
	height: 100% !important;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: inset 0px 0px 163px 46px rgba(0,0,0,0.15);
	overflow: hidden;

	@include breakpoint($screen-md) {
		padding: 0 15px;
	}
}

div#login-box {
	background-color: $white;
	width: 100%;
	max-width: 450px;
	position: relative;
	// padding: 48px 40px 36px;
	padding: 88px 40px 36px;
	margin: 15em auto 0;
	border: 1px solid #e3ded5;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);

	div#user-pic {
		background-color: $white;
		background-position: 50% 25%;
		background-size: cover;
		background-repeat: no-repeat;
		width: 140px;
		height: 140px;
		position: absolute;
		top: -70px;
		left: 50%;
		margin-left: -70px;
		border: 25px solid #edf1f5;
		@include border-radius(50%);
	}

	.form-group {
		position: relative;

		label {
			color: #ccc;
			position: absolute;
			top: 10px;
			left: 12px;
			z-index: 58;

			&:hover { pointer: text; }
		}

		input {
			width: 100%;
			border: 0;
			border-bottom: 1px solid #ccc;
			border-radius: 0;
			box-shadow: none;
			z-index: 57;
			transition: all .25s ease;

			/*&:hover {
				& + .lbl-login, & ~ .lbl-login {
					transform: translateY(-24px);
					font-size: 12px;
					color: #3483fa;
				}

				& + div.input-label-status, & ~ div.input-label-status { opacity: 1 }
			}*/

			&.active:focus + label, &.active:focus ~ label, &.active:not([value=""]) + label, &.active:not([value=""]) ~ label {
				transform: translate(-12px, -24px);
				font-size: 12px;
				color: #3483fa !important;
				transition: all .25s ease;
			}

			&.active:focus + div.input-label-status, &.active:focus ~ div.input-label-status { opacity: 1 !important; }
		}

		div.input-label-status {
			background-color: #3483fa;
			width: 100%;
			height: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
			opacity: 0;
			transition: opacity .25s ease;
		}
	}
}


/*
 * DROPZONE
 */

.form-galeria {
	display: none;

	&.open { display: block; }
}

.label-galeria {
	height: 32px;
	line-height: 32px;

	button { margin-left: -54px; }
}

.dropzone {
	background-color: #edf1f5 !important;
	min-height: 250px !important;
	margin-top: 17px;
	border: 2px dashed #0087f7 !important;
}

.dropzone .dz-message { margin: 4em 0 !important; }

.dropzone .dz-preview:hover .dz-actions { opacity: 1; }

.dropzone .dz-preview .dz-actions {
	width: 100%;
	height: 30px;
	padding: 0 5px;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -15px;
	opacity: 0;
	text-align: center;
	z-index: 12;

	li {
		display: inline-block;

		button {
			@extend .btn;
			@extend .btn-default;
			background-color: #2cabe3;
			padding: 4px 8px;
			color: $white;
			cursor: pointer;
			border: 1px solid #2cabe3;

			&:hover {
				background-color: rgba(44,171,227,.9);
				border: 1px solid #2cabe3;
			}

			i { margin-right: 0; }
		}
	}
}

.dz-image { border: 2px solid transparent; }

.dz-capa {

	&:after {
		content: 'Capa';
		background-color: rgba(0,0,0,.65);
		width: auto;
		height: 16px;
		padding: 0 4px;
		position: absolute;
		top: 7px;
		right: 7px;
		font-size: $font-9;
		line-height: 16px;
		color: $white;
		@include border-radius(20px);
		z-index: 99;
	}

	.dz-image {
		border: 2px solid #2cabe3;
		// @include border-radius(21px);
	}
}


/*
 * SIR TREVOR
 */

.st-format-bar { z-index: 9999 !important; }


/*
 * TOASTR
 */

.jq-toast-wrap.top-right {
	top: 80px !important;
	right: 20px !important; 
}


/*
 * DANTE2 EDITOR
 */

.dante-container {
	position: inherit;
}

.postArticle {
	margin-top: -25px;
}

p.data{
	font-size: 12px;
	color:#999;
}

h5.titulo{
	font-size: 16px;
}

p.observacao{
	font-size: 12px;
}

a.link{
	font-size: 12px;
	text-decoration: none !important;
}

footer {
    // background: #ffffff none repeat scroll 0 0;
    position: absolute;
    // padding: 20px 30px;
    left: 0;
    right: 0;
    // bottom: 0;
    bottom: 80px;
    text-align: center;
    font-size: $font-12;
    // color: #58666e;
    color: #97999f;
}