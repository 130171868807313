/*
 * FONTS
 */

// custom fonts
.fw-light { font-weight: $fw-light; }
.fw-regular { font-weight: $fw-regular; }
.fw-medium { font-weight: $fw-medium; }
.fw-bold { font-weight: $fw-bold; }
.fw-black { font-weight: $fw-black; }

// sizes
.font-9 { font-size: $font-9; }
.font-10 { font-size: $font-10; }
.font-11 { font-size: $font-11; }
.font-12 { font-size: $font-12; }
.font-14 { font-size: $font-14; }
.font-15 { font-size: $font-15; }
.font-18 { font-size: $font-18; }
.font-19 { font-size: $font-19; }
.font-20 { font-size: $font-20; }
.font-22 { font-size: $font-22; }
.font-24 { font-size: $font-24; }
.font-25 { font-size: $font-25; }
.font-26 { font-size: $font-26; }
.font-28 { font-size: $font-28; }
.font-30 { font-size: $font-30; }
.font-34 { font-size: $font-34; }
.font-36 { font-size: $font-36; }
.font-38 { font-size: $font-38; }
.font-40 { font-size: $font-40; }
.font-45 { font-size: $font-45; }
.font-46 { font-size: $font-46; }
.font-48 { font-size: $font-48; }
.font-50 { font-size: $font-50; }

// colors
.text-color { color: $text-color; }
.black { color: $black; }
.white { color: $white; }
.grey { color: $grey; }

// etc
.upper { text-transform: uppercase; }


/*
 * MARGINS/PADDINGS
 */

// margins
.m-t-15 { margin-top: 15px; }
.m-t-45 { margin-top: 45px; }
.m-r-10 { margin-right: 10px !important; }


/*
 * GENERAL
 */

.text-center { text-align: center; }
.text-right { text-align: right; }
.erroInput { border: 1px solid #f00 !important; }
.clear-fix { clear: both; }

/* MOBILE */

@include breakpoint($screen-md) {

	.text-center-sm { text-align: center !important; }

	.margin-top-30-sm { margin-top: 30px; }

}